import { Table, TableBody, TableHeader } from 'src/components/Table';
import ItemsByRoute from './ItemsByRoute';
import { Route } from 'src/graphql/types/delivery';

const labels: string[] = [
  'Route and Stop',
  'Location',
  'Vendor',
  'Order Links',
  'Order Lines',
];

const ItemsTable = ({
  routes,
  searchValue,
}: {
  routes: Route[];
  searchValue: string;
}): JSX.Element => {
  return (
    <Table id="itemsTable">
      <TableHeader labels={labels} />
      <TableBody>
        {routes?.map((route) => (
          <ItemsByRoute
            key={route.id}
            route={route}
            searchValue={searchValue}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ItemsTable;
