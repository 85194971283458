import { gql } from 'urql';
import { PRODUCT } from '../client';
import { ISSUE_FRAGMENT } from './fragments';

export const GET_ALL_STOP_STATUSES = gql`
  ${ISSUE_FRAGMENT}
  query ${PRODUCT}GetAllStopStatuses($input: RoutesInput!) {
    routes(input: $input) {
      id
      stops {
        ... on Delivery {
          id
          deliveryStatusDmp
          compostCollectionRequired
          compostCollectedAt
          donationRequired
          donationCollectedAt
          deliveryNote
          issues {
            ...IssueFragment
          }
          orders {
            id
            warehouseNote
            deliveredAt
            partiallyDelivered
            location {
              membershipTier
              locationDeliveryWindow {
                startTime
                endTime
              }
              isDoordash
            }
            issues {
              ...IssueFragment
            }
            vendor {
              name
              isWarehouse
            }
            orderLines {
              id
              sku {
                id
                refrigerationState
              }
            }
          }
          reminderMessage
          hasRepeatedAccessIssues
          isFirstDelivery
        }
        ... on PickUp {
          id
          deliveryStatusDmp
          issues {
            ...IssueFragment
          }
          orders {
            id
            warehouseNote
            collectedAt
            location {
              membershipTier
              locationDeliveryWindow {
                startTime
                endTime
              }
            }
            issues {
              ...IssueFragment
            }
          }
          reminderMessage
        }
      }
      redeliveryStops {
        id
        deliveryStatusDmp
        hasRepeatedAccessIssues
        reminderMessage
        deliveryStop {
          id
        }
        issues {
          ...IssueFragment
        }
        orders {
          id
          warehouseNote
          location {
            membershipTier
            locationDeliveryWindow {
              startTime
              endTime
            }
            isDoordash
          }
          issues {
            ...IssueFragment
          }
          vendor {
            name
            isWarehouse
          }
          orderLines {
            id
            sku {
              id
              refrigerationState
            }
          }
        }
      }
    }
  }
`;
