/* eslint-disable max-nested-callbacks */
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Divider, { DividerTypes } from 'src/components/Divider';
import { deliveryPath } from 'src/components/Navbar';
import OrderLineInfo from 'src/components/OrderLineInfo';
import OrderLinks from 'src/components/OrderLinks';
import { LoadingRow, TableCell, TableRow } from 'src/components/Table';
import { GET_ITEMS } from 'src/graphql/requests/GetItems';
import {
  Delivery,
  GetItemsDelivery,
  GetItemsOrder,
  OrderLine,
  PickUp,
  Route,
} from 'src/graphql/types/delivery';
import {
  GDeliveryStatusDmp,
  GGetItemsPerRouteQuery,
} from 'src/graphql/types/generated';
import { useAppDispatch } from 'src/hooks/store';
import { useTheme } from 'src/hooks/theme';
import { setStopDetailsCardVisible } from 'src/state/slices/delivery';
import { useQuery } from 'urql';

type Order = GetItemsOrder & {
  stop: Omit<GetItemsDelivery, 'orders'>;
};

export const ItemsByRoute = ({
  route,
  searchValue,
}: {
  route: Route;
  searchValue: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    theme: {
      colors: { secondaryBackgroundActive },
    },
  } = useTheme();
  const [orders, setOrders] = useState<Order[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [{ data, fetching, error, stale }] = useQuery<GGetItemsPerRouteQuery>({
    query: GET_ITEMS,
    variables: { input: { id: route.id } },
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!data) return;
    const deliveryStops =
      data.route.stops?.filter((stop) => stop.__typename === 'Delivery') ?? [];
    const myOrders: Order[] = [];
    deliveryStops.forEach((stop) => {
      const { orders, ...restOfStop } = stop as GetItemsDelivery;
      orders?.forEach((order) => {
        myOrders.push({ ...order, stop: restOfStop });
      });
    });
    data.route.redeliveryStops.forEach((stop) => {
      const { orders, ...restOfStop } = stop as GetItemsDelivery;
      orders?.forEach((order) => {
        myOrders.push({ ...order, stop: restOfStop });
      });
    });
    setOrders(myOrders);
  }, [data]);

  useEffect(() => {
    if (orders.length === 0) return;
    if (searchValue === '') {
      setFilteredOrders(orders);
      return;
    }
    const myOrders: Order[] = [];
    orders.forEach((order) => {
      const isItemThere = order.orderLines.some((orderLine) => {
        return orderLine.sku.name
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase());
      });
      if (isItemThere) myOrders.push(order);
    });
    setFilteredOrders(myOrders);
  }, [orders, searchValue]);

  if (fetching) return <LoadingRow numCells={5} />;
  if (!data)
    return <div sx={{ variant: 'text.body' }}>Something went wrong.</div>;

  return (
    <>
      {filteredOrders.map((order: Order) => {
        return (
          <TableRow key={order.id} data-test-id={order.id}>
            <TableCell
              data-test-id={`item-route-stop-cell-${order.id}`}
              style={{ cursor: 'pointer' }}
              onClick={(): void => {
                const myStop: Delivery | PickUp | undefined = route.stops?.find(
                  (myStop) => myStop.id === order.stop.id,
                );
                if (myStop)
                  dispatch(
                    setStopDetailsCardVisible({
                      stop: myStop,
                      routeId: route.id,
                    }),
                  );
                navigate(`${deliveryPath}?${searchParams.toString()}`);
              }}
            >
              {route.name} Stop {Number(order.stop.sequenceNumber ?? 0) + 1}
            </TableCell>
            <TableCell data-test-id={`item-location-cell-${order.id}`}>
              {order.stop.location?.businessName}
            </TableCell>
            <TableCell data-test-id={`item-vendor-cell-${order.id}`}>
              {order.vendor.name}
            </TableCell>
            <TableCell data-test-id={`item-links-cell-${order.id}`}>
              <OrderLinks orderId={order.id} netsuiteUrl={order.erpRecordUrl} />
            </TableCell>
            <TableCell data-test-id={`item-order-lines-cell-${order.id}`}>
              {order.orderLines.map((orderLine, orderLineIndex) => (
                <div key={orderLine.id}>
                  <OrderLineInfo
                    itemLevelTraking={order.itemLevelTracking}
                    orderLine={orderLine as OrderLine}
                    stopId={order.stop.id}
                    orderId={order.id}
                    isStopVisited={
                      order.stop.deliveryStatusDmp !==
                      GDeliveryStatusDmp.InTransit
                    }
                    editable={false}
                    searchValue={searchValue}
                  />
                  {orderLineIndex < order.orderLines.length - 1 && (
                    <Divider
                      type={DividerTypes.dash}
                      color={secondaryBackgroundActive}
                      style={{ marginTop: '16px', marginBottom: '16px' }}
                    />
                  )}
                </div>
              ))}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

export default ItemsByRoute;
