import {
  GGetWarehousesQuery,
  GGetUsersQuery,
  GGetStopDetailsQuery,
  GGetRoutesQuery,
  GGetStopAddressQuery,
  GGetStopDeliveryDetailsQuery,
  GMoveDeliveryStopsToRouteMutation,
  GRoutingImportPlanStatus,
  GGetRouteIssuesQuery,
  GGetAllStopStatusesQuery,
  GGetRedeliveryStopDetailsQuery,
  GGetRouteStopsDeliveryTimesQuery,
  GGetLocationsQuery,
  GLocationMembershipTier,
  GGetWarehouseOrderCutOffTimeQuery,
  GGetPrimaryStopStatusesQuery,
  GGetPickingLabelDataQuery,
  GGetFulfillmentDeltasPerRouteQuery,
  GGetWarehouseDeliveryInfoQuery,
  GGetItemsPerRouteQuery,
  GGetOrdersQuery,
  GGetOrderDetailsQuery,
  GGetPackingListQuery,
} from './generated';

export type ArrayElement<ArrayType extends unknown[] | undefined> =
  ArrayType extends (infer ElementType)[] ? ElementType : never;

export enum StopType {
  Delivery = 'Delivery',
  PickUp = 'PickUp',
  Redelivery = 'Redelivery',
}

export type UserError = { __typename: string };

export type RouteImportPlan = {
  __typename?: string;
  id: string;
  warehouseId: string;
  status: GRoutingImportPlanStatus;
  truckCount?: number;
  label?: string;
};

export type RouteImportState = {
  isError: boolean;
  isLoading: boolean;
  isRunning: boolean;
  isUpdateTruckMutationLoading: boolean;
  warehouseIdEditing?: string;
  plans?: RouteImportPlan[];
  isPastWarehouseCutOffTime: boolean;
};

export enum Tense {
  past = 'past',
  present = 'present',
  future = 'future',
}

// GGetWarehousesQuery
export type Warehouse = ArrayElement<GGetWarehousesQuery['warehouses']>;

// GUsersQuery
export type User = ArrayElement<GGetUsersQuery['users']>;

// GGetStopAddressQuery
export type GetStopAddressDelivery = Extract<
  GGetStopAddressQuery['stop'],
  { __typename?: 'Delivery' }
>;
export type GetStopAddressLocation = Extract<
  GetStopAddressDelivery['location'],
  { __typename?: 'Location' }
>;
export type GetStopAddress = GetStopAddressLocation['address'];

export type Location = {
  __typename?: 'Location';
  membershipTier: GLocationMembershipTier;
  businessName: string;
  address: GeoCoordinates;
  locationDeliveryWindow: { startTime: string; endTime: string };
};

// GGetRoutesQuery
export type Route = ArrayElement<GGetRoutesQuery['routes']>;
export type Stop = ArrayElement<Route['stops']>;
export type Redelivery = ArrayElement<Route['redeliveryStops']>;
export type Delivery = Extract<Stop, { __typename?: 'Delivery' }>;
export type PickUp = Extract<Stop, { __typename?: 'PickUp' }>;
export type Vendor = PickUp['vendor'];
export type GeoCoordinates = Vendor['address'];
export type Driver = Route['driverUser'];

// GGetStopDetailsQuery
export type GetDropOffDetailsDelivery = Extract<
  GGetStopDetailsQuery['stop'],
  { __typename?: 'Delivery' }
>;
export type GetPickUpDetailsDelivery = Extract<
  GGetStopDetailsQuery['stop'],
  { __typename?: 'PickUp' }
>;
export type Issue =
  | ArrayElement<GetDropOffDetailsDelivery['issues']>
  | ArrayElement<GetPickUpDetailsDelivery['issues']>;
export type Order =
  | ArrayElement<GetDropOffDetailsDelivery['orders']>
  | ArrayElement<GetPickUpDetailsDelivery['orders']>;
export type OrderLine = ArrayElement<
  ArrayElement<GetDropOffDetailsDelivery['orders']>['orderLines']
>;
export type DeliveryOrder = ArrayElement<GetDropOffDetailsDelivery['orders']>;
export type PickUpOrder = ArrayElement<GetPickUpDetailsDelivery['orders']>;

// GGetRedeliveryStopDetailsQuery
export type GetRedeliveryStopDetailsStop =
  GGetRedeliveryStopDetailsQuery['redeliveryStop'];
export type RedeliveryOrder = ArrayElement<
  GetRedeliveryStopDetailsStop['orders']
>;

// GMoveDeliveryStopsToRouteMutation
export type DeliveryStopsMoveToRoutePayload =
  GMoveDeliveryStopsToRouteMutation['moveDeliveryStopsToRoute'];

// GGetStopDeliveryDetailsQuery
export type GetStopDeliveryDetailsDelivery = Extract<
  GGetStopDeliveryDetailsQuery['stop'],
  { __typename?: 'Delivery' }
>;
export type Attachment = ArrayElement<
  GetStopDeliveryDetailsDelivery['attachments']
>;

// GGetAllStopStatusesQuery
export type GetStopStatusMyStop = ArrayElement<
  ArrayElement<GGetAllStopStatusesQuery['routes']>['stops']
>;
export type GetStopStatusMyRedeliveryStop = ArrayElement<
  ArrayElement<GGetAllStopStatusesQuery['routes']>['redeliveryStops']
>;
export type GetStopStatusDeliveryStop = Extract<
  GetStopStatusMyStop,
  { __typename?: 'Delivery' }
>;
export type GetStopStatusPickUpStop = Extract<
  GetStopStatusMyStop,
  { __typename?: 'PickUp' }
>;
export type StopStatusType = ArrayElement<
  ArrayElement<GGetAllStopStatusesQuery['routes']>['stops']
>;
export type GetStopStatusOrder =
  | ArrayElement<GetStopStatusDeliveryStop['orders']>
  | ArrayElement<GetStopStatusPickUpStop['orders']>;

export type GetAllStopStatusesRoute = ArrayElement<
  GGetAllStopStatusesQuery['routes']
>;

// GGetPrimaryStopStatusesQuery
export type GetPrimaryStopStatuses = ArrayElement<
  GGetPrimaryStopStatusesQuery['routes']
>;

// GGetRouteIssuesQuery
export type GetRouteIssuesMyStop = ArrayElement<
  GGetRouteIssuesQuery['route']['stops']
>;
export type GetRouteIssuesDeliveryStop = Extract<
  GetRouteIssuesMyStop,
  { __typename?: 'Delivery' }
>;
export type GetRouteIssuesOrder = ArrayElement<
  GetRouteIssuesDeliveryStop['orders']
>;
export type GetRouteIssuesOrderLine = ArrayElement<
  GetRouteIssuesOrder['orderLines']
>;
export type GetRouteIssuesOrderLineIssue = ArrayElement<
  GetRouteIssuesOrder['issues']
>;

// Omit<A, 'x'> & { x: number };
// GGetRouteStopsDeliveryTimeQuery
export type GetRouteStopsDeliveryTimeRoute =
  GGetRouteStopsDeliveryTimesQuery['route'];
export type GetRouteStopsDeliveryTimeStop = ArrayElement<
  GetRouteStopsDeliveryTimeRoute['stops']
>;
export type GetRouteStopsDeliveryTimeDelivery = Extract<
  GetRouteStopsDeliveryTimeStop,
  { __typename?: 'Delivery' }
>;
export type GetRouteStopsDeliveryTimePickUp = Extract<
  GetRouteStopsDeliveryTimeStop,
  { __typename?: 'PickUp' }
>;
export type GetRouteStopsDeliveryOrder = ArrayElement<
  GetRouteStopsDeliveryTimeDelivery['orders']
>;
export type GetRouteStopsDeliveryTimeRedeliveryStop = ArrayElement<
  GetRouteStopsDeliveryTimeRoute['redeliveryStops']
>;
export type GetRouteStopsRedeliveryOrder = ArrayElement<
  GetRouteStopsDeliveryTimeRedeliveryStop['orders']
>;

// GGetLocationsQuery
export type GetLocation = ArrayElement<
  GGetLocationsQuery['warehouse']['locations']
>;

// GGetWarehouseOrderCutoffTimeQuery
export type GetWarehouseOrderCutoffTimeQuery = Extract<
  GGetWarehouseOrderCutOffTimeQuery['stop'],
  { __typename?: 'Delivery' }
>;

// GGetPickingLabelDataQuery
export type GetPickingLabelDataRoute = ArrayElement<
  GGetPickingLabelDataQuery['routes']
>;

// GGetFulfillmentDeltasPerRouteQuery
export type GetFulfillmentDelivery = Extract<
  ArrayElement<GGetFulfillmentDeltasPerRouteQuery['route']['stops']>,
  { __typename?: 'Delivery' }
>;

export type GetFulfillmentOrder = ArrayElement<
  GetFulfillmentDelivery['orders']
>;

// GGetFulfillmentDeltasPerRouteQuery
export type GetItemsDelivery = Extract<
  ArrayElement<GGetItemsPerRouteQuery['route']['stops']>,
  { __typename?: 'Delivery' }
>;

export type GetItemsOrder = ArrayElement<GetItemsDelivery['orders']>;

// refrigeration state
export enum RefrigerationState {
  refrigerated = 'refrigerated',
  frozen = 'frozen_state',
}

// GGetWarehouseDeliveryInfoQuery
export type GetWarehouseDeliveryInfoRoute = ArrayElement<
  GGetWarehouseDeliveryInfoQuery['routes']
>;
export type GetWarehouseDeliveryInfoStop = ArrayElement<
  GetWarehouseDeliveryInfoRoute['stops']
>;
export type GetWarehouseRedeliveryInfoStop = ArrayElement<
  GetWarehouseDeliveryInfoRoute['redeliveryStops']
>;
export type GetWarehouseDeliveryInfoDelivery = Extract<
  GetWarehouseDeliveryInfoStop,
  { __typename?: 'Delivery' }
>;
export type GetWarehouseDeliveryInfoPickUp = Extract<
  GetWarehouseDeliveryInfoStop,
  { __typename?: 'PickUp' }
>;
export type GetWarehouseDeliveryInfoOrder = ArrayElement<
  GetWarehouseDeliveryInfoDelivery['orders']
>;
export type GetWarehouseDeliveryInfoOrderLine = ArrayElement<
  GetWarehouseDeliveryInfoOrder['orderLines']
>;
export type GetWarehouseDeliveryItem =
  GetWarehouseDeliveryInfoOrderLine['sku']['item'];

// GGetOrdersQuery
export type GetOrders = GGetOrdersQuery['orders'];
export type GetOrderNode = ArrayElement<GGetOrdersQuery['orders']['nodes']>;

// GGetOrderDetailsQuery
export type OrderDetails = Extract<
  GGetOrderDetailsQuery['order'],
  { __typename?: 'Order' }
>;
export type LocationOrderDetails = Extract<
  OrderDetails['location'],
  { __typename?: 'Location' }
>;
export type AddressOrderDetails = Extract<
  LocationOrderDetails['address'],
  { __typename?: 'Address' }
>;

// GGetPackingListQuery
export type GetPackingListDelivery = Extract<
  ArrayElement<GGetPackingListQuery['route']['stops']>,
  { __typename?: 'Delivery' }
>;
export type GetPackingListOrder = ArrayElement<
  GetPackingListDelivery['orders']
>;
export type GetPackingListOrderLine = ArrayElement<
  GetPackingListOrder['orderLines']
>;
