import Select, {
  ActionMeta,
  CSSObjectWithLabel,
  GroupBase,
  OptionProps,
  SingleValue,
} from 'react-select';
import { useTheme } from '../hooks/theme';
import CheckMark from '../../assets/CheckMark.svg';

export enum DropdownType {
  body = 'body',
  heading = 'heading',
}

export type Option = {
  value: string;
  label: string;
  component?: JSX.Element;
};

type DropdownProps = {
  onChange: (
    newValue: SingleValue<Option>,
    actionMeta: ActionMeta<Option>,
  ) => void;
  options: Option[];
  value?: string;
  id?: string;
  type?: DropdownType;
  isSearchable?: boolean;
  placeholder?: string;
};

const CustomOption = ({
  innerProps,
  isSelected,
  data,
}: OptionProps<Option, false, GroupBase<Option>>): JSX.Element | null => (
  <div
    {...innerProps}
    id={data.value}
    sx={{
      fontFamily: isSelected ? 'heading' : 'body',
      margin: '3px 12px',
      borderRadius: 1,
      display: 'flex',
      padding: isSelected ? '6px 12px' : '6px 10px 6px 40px',
      '&:hover': {
        backgroundColor: 'backgroundWeak',
        cursor: 'pointer',
      },
      justifyContent: 'space-between',
    }}
  >
    <div>
      {isSelected && (
        <span sx={{ paddingRight: '5px' }}>
          <CheckMark />
        </span>
      )}{' '}
      {data.label}
    </div>
    <div>{data.component}</div>
  </div>
);

const Dropdown = ({
  onChange,
  value,
  id,
  options,
  type = DropdownType.body,
  isSearchable = false,
  placeholder,
}: DropdownProps): JSX.Element => {
  let selectedOption = options.find((option) => option.value === value);

  const {
    theme: {
      fonts: { body, heading },
      colors: { text, secondaryBackgroundActive, backgroundMedium },
    },
  } = useTheme();

  const controlHeading = ({
    isFocused,
  }: {
    isFocused: boolean;
  }): CSSObjectWithLabel => ({
    fontSize: '24px',
    borderStyle: 'none',
    boxShadow: isFocused ? `0 0 0 1px ${backgroundMedium}` : 'none',
  });

  const controlBody = ({
    isFocused,
  }: {
    isFocused: boolean;
  }): CSSObjectWithLabel => ({
    fontSize: '14px',
    borderStyle: 'solid',
    boxShadow: 'none',
    borderColor: isFocused
      ? `${secondaryBackgroundActive}`
      : `${backgroundMedium}`,
    '&:hover': {
      borderColor: `${secondaryBackgroundActive}`,
    },
  });

  const control = ({ isFocused }: { isFocused: boolean }): CSSObjectWithLabel =>
    type === DropdownType.heading
      ? controlHeading({ isFocused })
      : controlBody({ isFocused });

  const customStyles = {
    dropdownIndicator: () => ({
      color: text,
      padding:
        type === DropdownType.heading
          ? '14px 30px 10px 0px'
          : '10px 10px 6px 10px',
    }),
    control: (
      provided: CSSObjectWithLabel,
      { isFocused }: { isFocused: boolean },
    ) => ({
      ...provided,
      cursor: 'pointer',
      fontFamily: body,
      ...control({ isFocused }),
    }),
    singleValue: (provided: CSSObjectWithLabel) => ({
      ...provided,
      fontFamily: type === DropdownType.heading ? heading : body,
      paddingRight: type === DropdownType.heading ? '0px' : '30px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      padding: '10px 0px',
      maxHeight: '236px',
      zIndex: 150,
      minWidth: '130px',
    }),
    menuList: (provided: CSSObjectWithLabel) => ({
      ...provided,
      maxHeight: '216px',
      scroll: 'auto',
    }),
    placeholder: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: 'black',
    }),
    noOptionsMessage: (provided: CSSObjectWithLabel) => ({
      ...provided,
      fontFamily: body,
    }),
  };
  return (
    <Select
      id={id}
      onChange={onChange}
      options={options}
      value={selectedOption}
      styles={customStyles}
      isSearchable={isSearchable}
      components={{ Option: CustomOption }}
      placeholder={placeholder}
    />
  );
};

export default Dropdown;
