import { Spacer } from '@odekoteam/doppio';
import { Flex } from 'theme-ui';
import NewTabLink from './NewTabLink';
import config from '../config';

const OrderLinks = ({
  netsuiteUrl,
  orderId,
}: {
  netsuiteUrl?: string;
  orderId: string;
}): JSX.Element | null => {
  const { so_api_url: soApiUrl } = config();
  const fullSoApiUrl = `${soApiUrl}/manage/orders/${orderId}`;

  return (
    <Flex>
      <NewTabLink url={fullSoApiUrl} id="soapi-order-link">
        soAPI Order
      </NewTabLink>
      <Spacer width="$4" />
      {netsuiteUrl && (
        <NewTabLink url={netsuiteUrl} id="netsuite-order-link">
          Netsuite Order
        </NewTabLink>
      )}
    </Flex>
  );
};

export default OrderLinks;
