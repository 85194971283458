import { ReactNode } from 'react';
import NavHamburger from './Navbar/NavHamburger';
import { useBreakpointIndex } from '@theme-ui/match-media';

const LoggedInContainer = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const breakpointIndex = useBreakpointIndex();
  return (
    <div
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: breakpointIndex === 0 ? 'column' : 'row',
      }}
    >
      <NavHamburger />
      {children}
    </div>
  );
};

export default LoggedInContainer;
