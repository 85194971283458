import { TableHeader } from 'src/components/Table';
import { useQuery } from 'urql';
import { GET_LOCATIONS } from 'src/graphql/requests/getLocations';
import { selectedWarehouseSelector } from 'src/state/selectors/delivery';
import { GGetLocationsQuery } from 'src/graphql/types/generated';
import { useAppSelector } from 'src/hooks/store';
import LocationRow from './LocationRow';
import { Flex } from 'theme-ui';
import { useEffect, useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { GetLocation } from 'src/graphql/types/delivery';
import { tableStyle } from '../Table/style';
import {
  NativeSelect,
  Autocomplete,
  Spacer,
  TextInput,
  Button,
} from '@odekoteam/doppio';
import SearchSvg from 'assets/search.svg';
import Loader from './Loader';
import {
  DeliveryWindowFilter,
  Item,
  RoutingFilter,
  allCitiesItem,
  allStatesItem,
  alphabeticalByName,
  deliveryWindowFilterItems,
  getFilteredLocations,
  routingFilterItems,
  uniqueItems,
} from './utils';
import { useDispatch } from 'react-redux';
import { ModalType, showModal } from 'src/state/slices/modal';

const LocationsTable = (): JSX.Element => {
  const dispatch = useDispatch();
  const selectedWarehouse = useAppSelector(selectedWarehouseSelector);
  const [filteredLocations, setFilteredLocations] = useState<GetLocation[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<GetLocation[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  // filters
  const [searchValue, setSearchValue] = useState('');
  const [routingFilter, setRoutingFilter] = useState(
    RoutingFilter.allLocations,
  );
  const [deliveryWindowFilter, setDeliveryWindowFilter] = useState(
    DeliveryWindowFilter.allWindows,
  );
  const [stateFilter, setStateFilter] = useState(allStatesItem.value);
  const [cityFilter, setCityFilter] = useState(allCitiesItem.value);
  const [zipFilter, setZipFilter] = useState('');
  // dropdown inputs
  const [stateItems, setStateItems] = useState<Item[]>([allStatesItem]);
  const [cityItems, setCityItems] = useState<Item[]>([allCitiesItem]);
  const [zipItems, setZipItems] = useState<Item[]>([]);

  const [{ data, fetching, error }] = useQuery<GGetLocationsQuery>({
    query: GET_LOCATIONS,
    variables: { warehouseId: selectedWarehouse?.id },
    pause: !selectedWarehouse?.id,
  });

  const labels = [
    '<select>',
    'Location Id',
    'Location',
    'Delivery Window',
    'City',
    'State',
    'Zip Code',
    'Automatic Routing',
  ];

  const onHeaderSelected = (value: boolean): void => {
    let newSelectedLocations: GetLocation[] = [...selectedLocations];
    filteredLocations.forEach((filteredLocation) => {
      const locationIndex = selectedLocations.findIndex(
        (selectedLocation) => selectedLocation.id === filteredLocation.id,
      );
      const isLocationSelected = locationIndex !== -1;
      if (value && !isLocationSelected) {
        // if checked, add to selected locations
        newSelectedLocations = [...newSelectedLocations, filteredLocation];
      } else if (!value && isLocationSelected) {
        // if unchecked, add to selected locations
        newSelectedLocations = newSelectedLocations.filter(
          (newSelectedLocation) =>
            newSelectedLocation.id !== filteredLocation.id,
        );
      }
    });
    setSelectedLocations(newSelectedLocations);
  };

  const onUpdateLocationsButtonClicked = (): void => {
    dispatch(
      showModal({
        type: ModalType.AutomatedRoutingConfirmation,
        dismissButtonLabel: 'Cancel',
        variables: {
          locations: selectedLocations,
        },
      }),
    );
  };

  const itemContent = (index: number, location: GetLocation): JSX.Element => (
    <LocationRow
      location={location}
      searchValue={searchValue}
      setSelectedLocations={setSelectedLocations}
      selectedLocations={selectedLocations}
    />
  );
  const fixedHeaderContent = (): JSX.Element => (
    <TableHeader
      labels={labels}
      onHeaderSelected={onHeaderSelected}
      isAllSelected={isAllSelected}
      setIsAllSelected={setIsAllSelected}
    />
  );
  const table = ({ style, ...props }: any): JSX.Element => (
    <table {...props} style={{ ...style, width: '92vw' }} />
  );

  // reset filters on warehouse change
  useEffect(() => {
    setSearchValue('');
    setRoutingFilter(RoutingFilter.allLocations);
    setDeliveryWindowFilter(DeliveryWindowFilter.allWindows);
    setStateFilter(allStatesItem.value);
    setCityFilter(allCitiesItem.value);
    setZipFilter('');
  }, [selectedWarehouse?.id]);

  useEffect(() => {
    const locations = data?.warehouse.locations ?? [];
    // get states
    const myStateItems = locations
      .map(
        (location: GetLocation): Item => ({
          value: location.address.state,
          label: location.address.state,
        }),
      )
      .filter(uniqueItems);
    setStateItems([allStatesItem, ...myStateItems]);
    // get cities
    const myCityItems = locations
      .map(
        (location: GetLocation): Item => ({
          value: location.address.city,
          label: location.address.city,
        }),
      )
      .filter(uniqueItems);
    setCityItems([allCitiesItem, ...myCityItems]);
    // get zips
    const myZipCodeItems = locations
      .map(
        (location: GetLocation): Item => ({
          value: location.address.postalCode,
          label: location.address.postalCode,
        }),
      )
      .filter(uniqueItems);
    setZipItems(myZipCodeItems);
  }, [data?.warehouse.locations, selectedWarehouse?.id]);

  useEffect(() => {
    const locations = data?.warehouse.locations ?? [];
    // if no filters are applied
    if (
      searchValue === '' &&
      routingFilter === RoutingFilter.allLocations &&
      deliveryWindowFilter === DeliveryWindowFilter.allWindows &&
      stateFilter === allStatesItem.value &&
      cityFilter === allCitiesItem.value &&
      zipFilter === ''
    ) {
      setFilteredLocations(locations.sort(alphabeticalByName));
      return;
    }
    const myFilteredLocations = getFilteredLocations([...locations], {
      searchValue,
      routingFilter,
      deliveryWindowFilter,
      stateFilter,
      cityFilter,
      zipFilter,
    });
    setFilteredLocations(myFilteredLocations.sort(alphabeticalByName));
  }, [
    searchValue,
    routingFilter,
    deliveryWindowFilter,
    data?.warehouse.locations,
    stateFilter,
    cityFilter,
    zipFilter,
  ]);

  return (
    <Flex sx={{ flexDirection: 'column', width: '100vw' }}>
      <Flex
        sx={{
          flexDirection: ['column', 'column', 'column', 'row'],
          justifyContent: 'space-between',
          marginBottom: '24px',
          width: '92vw',
        }}
      >
        <Flex sx={{ width: '850px' }}>
          <Button
            id="update-locations-button"
            isDisabled={selectedLocations.length === 0}
            onPress={onUpdateLocationsButtonClicked}
          >
            Edit {selectedLocations.length} locations
          </Button>
          <Spacer width="$1" />
          <TextInput
            iconLeft={<SearchSvg />}
            id="location-search"
            onChangeText={(value): void => setSearchValue(value)}
            placeholder="Search"
            value={searchValue}
          />
        </Flex>
        <Spacer height="$1" />
        <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <NativeSelect
            id="routing-input-controlled"
            items={routingFilterItems}
            value={routingFilter}
            onValueChange={(value): void =>
              setRoutingFilter(value as RoutingFilter)
            }
          />
          <Spacer width="$1" />
          <NativeSelect
            id="delivery-window-input-controlled"
            items={deliveryWindowFilterItems}
            value={deliveryWindowFilter}
            onValueChange={(value): void =>
              setDeliveryWindowFilter(value as DeliveryWindowFilter)
            }
          />
          <Spacer width="$1" />
          <NativeSelect
            id="state-input-controlled"
            items={stateItems}
            value={stateFilter}
            onValueChange={(value): void => setStateFilter(value as string)}
          />
          <Spacer width="$1" />
          <NativeSelect
            id="city-input-controlled"
            items={cityItems}
            value={cityFilter}
            onValueChange={(value): void => setCityFilter(value as string)}
          />
          <Spacer width="$1" />
          <Autocomplete
            id="zip-input-controlled"
            options={zipItems}
            value={zipFilter}
            placeholder="All Zip Codes"
            onChangeText={(value): void => setZipFilter(value)}
            onSelect={(option): void => setZipFilter(option.value as string)}
          />
        </Flex>
      </Flex>
      {fetching ? (
        <Loader labels={labels} />
      ) : (
        <TableVirtuoso
          sx={{ ...tableStyle }}
          data={filteredLocations}
          useWindowScroll
          fixedHeaderContent={fixedHeaderContent}
          itemContent={itemContent}
          components={{
            Table: table,
          }}
        />
      )}
    </Flex>
  );
};

export default LocationsTable;
