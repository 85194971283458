import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'src/hooks/store';
import {
  isModalVisibleSelector,
  modalSelector,
} from 'src/state/selectors/modal';
import { ModalType, hideModal } from 'src/state/slices/modal';
import ConfirmationModal from './ConfirmationModal';
import FullscreenModal from './FullscreenModal';
import CreateRouteButton from './CreateRouteButton';

import { container, backdrop } from './styles';
import UpdateTruckCountButton from './UpdateTruckCountButton';
import AutomatedRoutingConfirmation from './AutomatedRoutingConfirmationModal';

const CustomModal = (): JSX.Element | null => {
  const { type } = useAppSelector(modalSelector);
  const dispatch = useAppDispatch();
  const isModalVisible = useAppSelector(isModalVisibleSelector);

  const handleOnClickCancel = (): void => {
    dispatch(hideModal());
  };

  useEffect(() => {
    const close = (e: KeyboardEvent): void => {
      if (e.key === 'Escape' && type === ModalType.Fullscreen) {
        handleOnClickCancel();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  });

  if (!isModalVisible) return null;
  return (
    <div className="custom-modal" sx={container}>
      <div
        sx={backdrop}
        onClick={
          type === ModalType.Fullscreen ? handleOnClickCancel : (): void => {}
        }
      />
      {type === ModalType.CreateRoute && (
        <ConfirmationModal>
          <CreateRouteButton />
        </ConfirmationModal>
      )}
      {type === ModalType.UpdateTruckCount && (
        <ConfirmationModal>
          <UpdateTruckCountButton />
        </ConfirmationModal>
      )}
      {type === ModalType.AutomatedRoutingConfirmation && (
        <AutomatedRoutingConfirmation />
      )}
      {type === ModalType.Fullscreen && <FullscreenModal />}
    </div>
  );
};
export default CustomModal;
