import LoggedInContainer from 'src/components/LoggedInContainer';
import { Flex } from 'theme-ui';
import { headerWrapper, pageContainer } from '../styles';
import WarehouseDropdown from 'src/components/WarehouseDropdown';
import DatePickerDropdown from 'src/components/DatePicker';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { authSelector } from 'src/state/selectors/auth';
import { useEffect, useState } from 'react';
import analytics from 'src/services/analytics';
import { loginPath } from 'src/components/Navbar';
import {
  nightEndSelector,
  routesLoadingSelector,
  routesSelector,
  selectedWarehouseIdSelector,
} from 'src/state/selectors/delivery';
import { getRoutes } from 'src/state/slices/delivery';
import { clearRouteQuantities } from 'src/state/slices/fulfillmentDeltas';
import { TextInput } from '@odekoteam/doppio';
import SearchSvg from 'assets/search.svg';
import ItemsTable from 'src/components/SearchItems/ItemsTable';

const ItemSearch = (): JSX.Element => {
  const navigate = useNavigate();
  const { isUserAuthenticated } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const routes = useAppSelector(routesSelector);
  const routesLoading = useAppSelector(routesLoadingSelector);
  const nightEnd = useAppSelector(nightEndSelector);
  const selectedWarehouseId = useAppSelector(selectedWarehouseIdSelector);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    analytics.track(`"Issues" Page Visit`);
  }, []);

  useEffect(() => {
    if (!isUserAuthenticated) {
      navigate(loginPath);
    }
  }, [isUserAuthenticated, navigate]);

  useEffect(() => {
    dispatch(clearRouteQuantities());
    if (!routes && selectedWarehouseId && nightEnd.viewing) {
      dispatch(
        getRoutes({
          warehouseId: selectedWarehouseId,
          nightEnd: nightEnd.viewing,
        }),
      );
    }
  }, [selectedWarehouseId, nightEnd.viewing]);

  return (
    <LoggedInContainer>
      <Flex id="itemSearch" sx={pageContainer}>
        <div
          id="itemSearchHeader"
          sx={{ ...headerWrapper, flexDirection: ['column', 'row'] }}
        >
          <WarehouseDropdown />
          <DatePickerDropdown style={{ alignSelf: 'center' }} />
        </div>
        {(routesLoading || !routes) && (
          <Flex sx={{ fontFamily: 'body', justifyContent: 'center' }}>
            Loading...
          </Flex>
        )}
        {routes && (
          <>
            <Flex
              sx={{
                justifyContent: 'space-between',
                width: '100%',
                margin: '-10px 0px 12px 10px',
              }}
            >
              <TextInput
                iconLeft={<SearchSvg />}
                id="item-search"
                onChangeText={(value): void => setSearchValue(value)}
                placeholder="Search"
                value={searchValue}
              />
            </Flex>
            <ItemsTable routes={routes} searchValue={searchValue} />
          </>
        )}
      </Flex>
    </LoggedInContainer>
  );
};

export default ItemSearch;
